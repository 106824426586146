
import { Component, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import Api from '@/models/Api'
import WebMessage from '@/models/WebMessage'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import User from '@/models/User'
import OneSignalVue from 'onesignal-vue'
import moment from 'moment'
import TrustedDevice from '@/models/TrustedDevice'
import IconAction from '../../components/IconAction/IconAction.vue'
import CustomIcon from '../../components/CustomIcon/CustomIcon.vue'

@Component({
  components: {
    Widget,
    FormInput,
    IconAction,
    CustomIcon,
  },
})
export default class UserAccount extends ViewModel {
  public edit: string = ''

  public old_password: string = ''

  public mfa_key: string = ''

  public mfa_qr: string = ''

  public backup_codes: number[] = []

  public password: string = ''

  public password_confirmation: string = ''

  @Prop({ default: null })
  public oauth!: string | null

  public push_loading: boolean = false

  public push_enabled: boolean = false

  public mfa_confirmed_title: string = 'MFA Enabled!'

  public busy: boolean = false

  public device_fields: any = [
    {
      key: 'device_name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'created_at',
      label: 'Created',
      sortable: true,
      formatter: (value: string) => moment(value).format('MMMM, Do YYYY'),
    },
    {
      key: 'last_used_at',
      label: 'Last Used',
      sortable: true,
      formatter: (value: string) => (value ? moment(value).format('MMMM, Do YYYY LT') : 'N/A'),
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ]

  public oauth_providers = [
    /* {
      name: 'Salesforce',
      key: 'salesforce',
      icon: 'salesforce',
      loading: false,
    }, */
    {
      name: 'Google',
      key: 'google',
      icon: 'google',
      loading: false,
      show: () => this.user && this.user.isSuperAdmin,
    },
    {
      name: 'Quickbooks',
      key: 'quickbooks',
      icon: 'quickbooks',
      customIcon: 'quickbooks',
      loading: false,
      show: () => this.user && this.user.isSuperAdmin,
    },
  ]

  public get visible_oauth_providers() {
    return this.oauth_providers.filter(p => typeof p.show === 'undefined' || p.show())
  }

  public editName() {
    this.edit = 'name'
  }

  public editPassword() {
    this.edit = 'password'
    this.old_password = ''
    this.password = ''
    this.password_confirmation = ''
  }

  public enableMFA() {
    const api = new Api()
    this.busy = true
    api
      .post('user/mfa/enable')
      .then(response => {
        this.mfa_qr = response.data.result.qr_code
        this.edit = 'mfa'
        this.busy = false
      })
      .catch(error => {
        WebMessage.success('We were not able to process your request, please try again later.')
      })
  }

  public cancel() {
    this.edit = ''
    this.old_password = ''
    this.password = ''
    this.password_confirmation = ''
    this.mfa_key = ''
    this.mfa_qr = ''
    this.backup_codes = []
  }

  public subscribePush() {
    this.push_loading = true
    this.$OneSignal
      .showNativePrompt()
      .then(() => {
        this.$OneSignal.setSubscription(true).then(() => {
          this.push_loading = false
        })
      })
      .catch(() => {
        this.push_loading = false
      })
  }

  public unsubscribePush() {
    this.push_loading = true
    this.$OneSignal.setSubscription(false).then(() => {
      this.push_loading = false
    })
  }

  public removeDevice(device: TrustedDevice) {
    device.delete().then(r => {
      if (r.status === 200) {
        const u = User.toObject(this.user)
        u.trusted_devices = u.trusted_devices.filter(d => d.id !== device.id)
        getModule(SystemtModule).updateState({
          name: 'user',
          data: u,
        })
      }
    })
  }

  public connectOAuth(provider: string) {
    let grant = ''
    if (this.user.isRoot) {
      grant = '?with_grant=true'
    }
    window.location.href = `${process.env.VUE_APP_BASE_API_URL}oauth/${provider}/login${grant}`
  }

  public disconnectOAuth(provider: string) {
    const active_provider = this.oauth_providers.find(p => p.key == provider)
    this.oauth_providers.forEach(p => {
      if (p.key == provider) {
        p.loading = true
      }
    })

    const api = new Api()
    api
      .get(`oauth/${provider}/logout`)
      .then(response => {
        this.oauth_providers.forEach(p => {
          if (p.key == provider) {
            p.loading = false
          }
        })
        if (response.status == 200) {
          const system = getModule(SystemtModule)
          system.updateState({
            name: 'user',
            data: User.toObject(response.data.result.user),
          })
          if (active_provider) {
            WebMessage.success(`Successfully disconnected from ${active_provider.name}.`)
          }
        }
      })
      .catch(error => {
        this.oauth_providers.forEach(p => {
          if (p.key == provider) {
            p.loading = false
          }
        })
        WebMessage.success('We were not able to process your request, please try again later.')
      })
  }

  public completeOAtuh() {
    const api = new Api()
    const { query } = this.$route
    const provider = this.oauth_providers.find(p => p.key == this.oauth)

    this.oauth_providers.forEach(p => {
      if (p.key == this.oauth) {
        p.loading = true
      }
    })

    let additional_params = ''

    if (query.realmId) {
      additional_params = `&realm_id=${query.realmId}`
    }

    api
      .get(`oauth/${this.oauth}/callback?code=${query.code}${additional_params}`)
      .then(response => {
        this.oauth_providers.forEach(p => {
          if (p.key == this.oauth) {
            p.loading = false
          }
        })
        if (response.status == 200) {
          const system = getModule(SystemtModule)
          system.updateState({
            name: 'user',
            data: User.toObject(response.data.result.user),
          })
          if (provider) WebMessage.success(`Successfully connected to ${provider.name}.`)
          this.$router.push('/app/account')
        }
      })
      .catch(error => {
        this.oauth_providers.forEach(p => {
          if (p.key == this.oauth) {
            p.loading = false
          }
        })
        WebMessage.success('We were not able to process your request, please try again later.')
      })
  }

  public onSubmit() {
    if (this.edit === 'password') {
      this.user
        .changePassword({
          old_password: this.old_password,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(response => {
          if (response.status == 200) {
            this.edit = ''
          }
        })
      return
    }

    if (this.edit === 'mfa') {
      const api = new Api()
      this.busy = true
      api
        .post('user/mfa/confirm', {
          code: this.mfa_key,
        })
        .then(response => {
          this.mfa_key = ''
          this.mfa_qr = ''
          this.backup_codes = response.data.result.backup_codes
          this.mfa_confirmed_title = 'MFA Enabled!'
          this.edit = 'mfa_confirmed'
          this.busy = false

          // Update user
          const user = User.toObject(response.data.result.user)
          const system = getModule(SystemtModule)

          system.updateState({
            name: 'user',
            data: user,
          })
        })
        .catch(error => {
          this.mfa_key = ''
          this.busy = false
        })
    }
  }

  public disableMFA() {
    WebMessage.confirm(
      'Are you sure that you want to disable the MFA? This is an additional security layer to your account.',
      'Disable MFA',
      { okTitle: 'Disable MFA' },
    ).then((result: boolean) => {
      if (result) {
        const api = new Api()
        this.busy = true
        api
          .post('user/mfa/disable')
          .then(response => {
            const user = User.toObject(response.data.result.user)
            const system = getModule(SystemtModule)

            system.updateState({
              name: 'user',
              data: user,
            })
            this.busy = false
            WebMessage.success('MFA has been disabled.')
          })
          .catch(error => {
            this.mfa_key = ''
            this.busy = false
          })
      }
    })
  }

  public resetMFABackupCodes() {
    WebMessage.confirm(
      'Are you sure that you want to reset your recovery codes? This will remove all your current recovery codes.',
      'Reset Recovery Codes',
      { okTitle: 'Reset Recovery Codes' },
    ).then((result: boolean) => {
      if (result) {
        const api = new Api()
        this.busy = true
        api
          .post('user/mfa/reset')
          .then(response => {
            this.edit = 'mfa_confirmed'
            this.backup_codes = response.data.result.backup_codes
            this.mfa_confirmed_title = 'Recovery Codes Reset!'
            this.busy = false
            WebMessage.success('MFA has been disabled.')
          })
          .catch(error => {
            this.mfa_key = ''
            this.busy = false
          })
      }
    })
  }

  public created() {
    const { query } = this.$route

    if (this.oauth) {
      this.completeOAtuh()
    } else if (query.target === 'mfa' && !this.user.mfa_enabled) {
      this.enableMFA()
    }

    // @ts-ignore
    this.$OneSignal.on('subscriptionChange', (isSubscribed: any) => {
      this.push_loading = false
      if (isSubscribed) {
        this.$OneSignal.setExternalUserId(this.user.id)
      }
    })

    this.$OneSignal.isPushNotificationsEnabled().then(isEnabled => {
      this.push_enabled = isEnabled
    })
  }
}
